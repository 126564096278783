/*global define, console, document, window*/
(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("LogoMarquee", ["jquery", "Behaviors"], factory);
    } else {
        root.LogoMarquee = factory(root.jQuery, root.Behaviors);
    }
}(this, function ($, Behaviors) {
    "use strict";
    
    var module = {};

    function initSmoothScrolling(){

        var sliderWidth = 0;	
        var animationWidth = 0;	
        var sliderHeight = $('.LogoMarquee-thumbs > .LogoMarquee-slider_thumbs:first-of-type').outerHeight(false);
        var animation = 'smoothscroll';
        var container = '.LogoMarquee-inner[data-logo-container]';

        $('.LogoMarquee-thumbs > .LogoMarquee-slider_thumbs').each(function(){	
            animationWidth += $(this).outerWidth(false);		
        });
        
        $(container).css('width', $(window).width())
         var slidesVisible, largest;
        if(animationWidth < $(window).width()){
            largest = Math.max.apply(Math, $('.LogoMarquee-thumbs > .LogoMarquee-slider_thumbs').map(function(){ return $(this).outerWidth(); }).get());
        }else {
            largest = $('.LogoMarquee-thumbs > .LogoMarquee-slider_thumbs:first-of-type').outerWidth(false);	
        }
        
        slidesVisible = $(container).width() / largest;	        
        slidesVisible = Math.ceil(slidesVisible);


        var slidesNumber = $('.LogoMarquee-thumbs > .LogoMarquee-slider_thumbs').length;
        var speed = 120;
        //var speed = $(container).find('.LogoMarquee-thumbs').attr('data-logo-speed');

        $('.LogoMarquee-thumbs > .LogoMarquee-slider_thumbs').slice(0,slidesVisible).clone().appendTo($('.LogoMarquee-thumbs'));	

        $('.LogoMarquee-thumbs > .LogoMarquee-slider_thumbs').each(function(){
            sliderWidth += $(this).outerWidth(false);
        });

        $('.LogoMarquee-thumbs').css({'width':sliderWidth,'height':sliderHeight});
    
        // Inserting css animate to html
        $("<style type='text/css'>@keyframes "+animation+" { 0% { margin-left: 0px; } 100% { margin-left: -"+animationWidth+"px; } } .LogoMarquee-thumbs > .LogoMarquee-slider_thumbs:first-of-type { -webkit-animation: "+animation+" "+speed+"s linear infinite; -moz-animation: "+animation+" "+speed+"s linear infinite; -ms-animation: "+animation+" "+speed+"s linear infinite; -ms-animation: "+animation+" "+speed+"s linear infinite; animation: "+animation+" "+speed+"s linear infinite; }</style>").appendTo(".LogoMarquee-wrapper");
        
        $('.LogoMarquee-thumbs').css('visibility','visible')
        
    }
    
    var to = true,
    throttle = function(func, delay){
        if (to) {
            window.clearTimeout(to);
        }
        to = window.setTimeout(func, delay);
    };
   
    $(window).on('load resize', function(){
        throttle(function(){
            initSmoothScrolling();
        },250);
    });
    
    
   

    module.initSmoothScrolling = initSmoothScrolling;

    return module;
}));
