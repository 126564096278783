/*global define, console, document, window*/
(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("ExtendedProductTabs", ["jquery", "Behaviors"], factory);
    } else {
        root.ExtendedProductTabs = factory(root.jQuery, root.Behaviors);
    }
}(this, function ($, Behaviors) {
    "use strict";
    
    var module = {};
    
    jQuery( '.wc-tabs-wrapper, .woocommerce-tabs, #rating' ).trigger( 'init' );
    jQuery('.wc-tabs-wrapper.desktop .woocommerce-Tabs-panel').each(function(){
        var boxHeight = $(this).height();
        if(boxHeight > 299){
            $(this).addClass('scrollMe');
        }
    })
//     module.initSmoothScrolling = initSmoothScrolling;

    return module;
}));
