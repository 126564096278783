/*global define, console, document, window*/
(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("ProductCourseInfo", ["jquery", "Behaviors"], factory);
    } else {
        root.ProductCourseInfo = factory(root.jQuery, root.Behaviors);
    }
}(this, function ($, Behaviors) {
    "use strict";
    
    var module = {};

    
    function lessonsAccordion(){
        if($('.ProductCourseInfo-lessons-heading').length){
            $('.ProductCourseInfo-lessons-heading').click(function(){
                if($(this).hasClass('active')){
                    $(this).removeClass('active'); 
                }else{
                    $('.ProductCourseInfo-lessons-heading').removeClass('active'); 
                    $(this).addClass('active'); 
                }
//                 $('.ProductCourseInfo-lessons-content_inner').slideUp(300);
                $(this).parent().parent().siblings('.ProductCourseInfo-lessons-wrapper-item').find('.ProductCourseInfo-lessons-content_inner').slideUp(300);
                $(this).parent().parent().siblings('.ProductCourseInfo-lessons-wrapper-item').find('.ProductCourseInfo-lessons-info').slideUp(300);
                $(this).parent().siblings('.ProductCourseInfo-lessons-sidebar').find('.ProductCourseInfo-lessons-info').slideToggle(300);
                $(this).siblings('.ProductCourseInfo-lessons-content_inner').slideToggle(300);
                
            });
        }
    }
    

    lessonsAccordion();
    

    module.lessonsAccordion = lessonsAccordion;

    return module;
}));
