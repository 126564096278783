/*global define, console, document, window*/
(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("ArticleSingleInfo", ["jquery", "Behaviors"], factory);
    } else {
        root.ArticleSingleInfo = factory(root.jQuery, root.Behaviors);
    }
}(this, function ($, Behaviors) {
    "use strict";
    
    var module = {};
    
    function addTitle(){
        var headings = [];
        $('.ArticleSingleInfo-wrapper .ArticleSingleInfo-content .ArticleSingleInfo-wrapp > h2').each(function(index, value){
            headings.push($(this).text());
            if(index == 0){
                var heading = $('.ArticleSingleInfo-wrapper .ArticleSingleInfo-content .ArticleSingleInfo-wrapp h2').first();
            }
            
            index += 1;
            $(this).prepend('<span id="nav-'+index+'" data-section="#nav-'+index+'"></span>');
        });
        $.each(headings, function( index, value ) {
            index += 1;
            $('.ArticleSingleInfo-sidebar-list').append('<li class="toc-li ArticleSingleInfo-sidebar-list-item"><div class="top"><a class="subtitle" href="#nav-'+index+'"><div>'+index+'.</div> <div>'+value+'</div></a></div></li>');
        });

        $('.ArticleSingleInfo-sidebar-list .toc-li:first-child a[href*="#"]').addClass('active');
        $('.ArticleSingleInfo-sidebar-list .toc-li a[href*="#"]').on('click', function(event){  
            var headerHeight = $('header.siteheader').height()+30;   
            event.preventDefault();
            $(this).parent().parent().siblings('.toc-li').children('.top').children('a').removeClass('active');
            $(this).addClass('active');
            $('html,body').animate({scrollTop:$(this.hash).offset().top - headerHeight}, 800);
        });

        $(".ArticleSingleInfo-sidebar-list li a").each(function() {
            $(this).click(function(e){
                $(this).addClass('active');
            })
        });
    }

    function activeLi () { 
        var h2Titles = $(".ArticleSingleInfo-content .ArticleSingleInfo-wrapp h2");
        var navLi = document.querySelectorAll('.ArticleSingleInfo-sidebar-list .subtitle');
        
        window.onscroll = () => {
            var current = "";

            h2Titles.each(function(){
                var offsetTop = $(this).offset().top;
                var pageYOffset = window.pageYOffset;
                if (pageYOffset >= offsetTop - 300) {
                    var span = $(this).find('span')
                    //console.log("span", span)
                    current = span.attr('data-section');
                }

            })

            $(navLi).each(function() {
                var $this = $(this);
                $this.removeClass('active');
                var href = $this.attr("href");
                //console.log($this.attr("href") == current);
                if ($this.attr("href") == current) {
                    $this.addClass("active");
                }
            });
        };
    }
    
    addTitle();
    activeLi();
    
    module.addTitle = addTitle;
    module.activeLi = activeLi;

    return module;
}));
