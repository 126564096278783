/*global define, console, document, window*/
(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("ProductEventsWithFeaturedTag", ["jquery", "Behaviors"], factory);
    } else {
        root.ProductEventsWithFeaturedTag = factory(root.jQuery, root.Behaviors);
    }
}(this, function ($, Behaviors) {
    "use strict";
    
    var module = {};

    function initStickyScroll(){
        if($('.ProductEventsWithFeaturedTag-left_column').length){
            
        
            var headerHeight = $('header.siteheader').height();
            var top = $('.ProductEventsWithFeaturedTag-left_column').offset().top - headerHeight;
            var footTop = $('footer').offset().top - $('footer').outerHeight() - 230;
            var maxY = footTop - $('.ProductEventsWithFeaturedTag-left_column').outerHeight();
            var lastScrollTop = 0;

            var sections = document.getElementsByClassName("blockSection");
            var navLi = $(".Product-wrapper_sidebar .fl-list-item");

            window.onscroll = () => {
                var current = "";

                Array.from(sections).forEach((section) => {
                    const sectionTop = section.offsetTop;

                    if (pageYOffset >= sectionTop + 700) {
                        
                        current = $(section).attr('id'); 
                    
                    }
                });

                Array.from(navLi).forEach((li) => {
                    li.classList.remove("active");
                    
                    if (li.classList.contains(current) && (current != null)) {
                        li.classList.add("active");
                    }
                });
            }; 

            $(".ProductEventsWithFeaturedTag-left_column .fl-list-item-heading-text").each(function() {
                $(this).click(function(){
                    var clicked = $(this).text();
                    var stripped = clicked.replace(/\s+/g, '');
                    var newClicked = stripped.replace(/\//g, '');
                    
                    $(".ProductEventsWithFeaturedTag-left_column .fl-list-item").css('opacity','0.5');
                    $(this).parents(".fl-list-item").css('opacity','1');
                    
                    $('html, body').animate({
                        scrollTop: $('.ProductEventsWithFeaturedTag-right_column div.'+newClicked).offset().top - headerHeight
                    }, 800);
                })
                
            });
            
            $("#anchor-more a").click(function(){
                $('html, body').animate({
                    scrollTop: $('.ProductEventsWithFeaturedTag-right_column').offset().top - headerHeight
                }, 800);
            });
        }
    }
    

    
    initStickyScroll();
    
    module.initStickyScroll = initStickyScroll;

    return module;
}));
 
