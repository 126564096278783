/*global define, console, document, window*/
(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("ProductCartButtons", ["jquery", "Behaviors"], factory);
    } else {
        root.ProductCartButtons = factory(root.jQuery, root.Behaviors);
    }
}(this, function ($, Behaviors) {
    "use strict";
    
    var module = {};

    function initAddToCart(){
        $('.ajax_add_to_cart_wrapp form.cart').on('submit', function(e) {
            e.preventDefault();
            var form = $(this);
            form.block({ message: null, overlayCSS: { background: '#fff', opacity: 0.6 } });
            var formData = new FormData(form[0]);
            formData.append('add-to-cart', form.find('[name=add-to-cart]').val() );
//             var data = {
//                 action: 'woocommerce_ajax_add_to_cart',
//                 product_id: form.find('[name=add-to-cart]').val(),
//                 product_sku: '',
//                 quantity: form.find('[name=quantity]').val(),
//             };
            
            // Ajax action.
            $.ajax({
                url: wc_add_to_cart_params.wc_ajax_url.toString().replace( '%%endpoint%%', 'add_to_cart' ),
                data: formData,
                type: 'POST',
                processData: false,
                contentType: false,
                complete: function( response ) {
                    if ( ! response ) {
                        return;
                    }
                    
                    if ( response.error && response.product_url ) {
                        window.location = response.product_url;
                        return;
                    }
                    // Redirect to cart option
                    if ( wc_add_to_cart_params.cart_redirect_after_add === 'yes' ) {
                        window.location = wc_add_to_cart_params.cart_url;
                        return;
                    }
                    var $thisbutton = form.find('.single_add_to_cart_button'); 
    //				var $thisbutton = null; // uncomment this if you don't want the 'View cart' button
                    // Trigger event so themes can refresh other areas.
                    $( document.body ).trigger( 'added_to_cart', [ response.fragments, response.cart_hash, $thisbutton ] );
      
                    // Add new notices
//                 form.closest('.product').before(response.fragments.notices_html)
                    form.unblock();
                }
            });
        });
    
    
    }
    
    initAddToCart();
    
    module.initAddToCart = initAddToCart;

    return module;
}));
